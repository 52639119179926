import React from 'react'
import ResetPassword from '../../components/Users/ResetPassword'

const EmailVerification = () => {
  return (
    <>
        <ResetPassword/>
    </>
    
  )
}

export default EmailVerification