import React from 'react'
import NavBar from '../../components/Users/NavBar/NavBar'
import ApplyDoctor from '../../components/Users/ApplyDoctor'

const ApplyForDoctor = () => {
  return (
   <>
    <NavBar/>
    <ApplyDoctor/>
   </>
  )
}

export default ApplyForDoctor