import React from 'react'
import Chat from '../../components/Doctors/Chat/Body'


const DoctorChat = () => {
  return (
    <>
       <Chat/>
    </>
  )
}

export default DoctorChat