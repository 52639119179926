import React from 'react'
import DoctorApply from '../../components/Doctors/ApplyDoctor'

const ApplyDoctor = () => {
  return (
    <>
        <DoctorApply/>
    </>
  )
}

export default ApplyDoctor